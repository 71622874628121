import { Box, chakra, Text, useMediaQuery } from '@chakra-ui/react';
import { BackGroundTwo } from 'assets';
import { CustomDivider, FullScreenModal, HaveQuestionModal, Mp4VideoPlayer, RequestButton } from 'components';
import { useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: `url(${BackGroundTwo})`,
    backgroundPosition: { base: '400px 150px', md: 'center' },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
});

const Container = chakra(Box, {
  baseStyle: {
    width: { base: '100%', md: '78%', '3xlB': '62%' },
    margin: '0 auto',
    display: 'flex',
    flexDirection: { base: 'column', lg: 'row' },
    paddingBottom: { base: '120px', md: '240px' },
    justifyContent: { base: 'flex-start' },
  },
});

const Title = chakra(Box, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: { base: '3rem', lgA: '60px', '2xl': '82px', '4xl': '86px' },
    lineHeight: { base: '90%', md: '115%' },
    textTransform: 'capitalize',
    letterSpacing: '-0.01em',
    margin: { base: '32px 0', lg: '27px 0' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: { base: 500, md: 400 },
    fontSize: { base: '20px', md: '20px', xlA: '28px' },
    lineHeight: '150%',
    letterSpacing: '-0.02',
    maxWidth: '700px',
  },
});

const QuestionSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.1 });
  const [isMobileView] = useMediaQuery('(max-width: 768px)');
  const width = isMobileView ? '169px' : '182px';
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <OuterContainer>
      <Container>
        <Box
          background={primaryTheme.colors.brand.black}
          width={{
            base: '100% !important',
            lg: '70% !important',
            '3xlB': '55% !important',
          }}
          maxWidth={{ base: undefined, lg: '635px !important' }}
          aspectRatio={1.3}
        >
          <Box opacity="0.45">
            <Mp4VideoPlayer
              videoUrl="https://d3kobf7vi81n42.cloudfront.net/ANNOUNCEMENT/AppleHLS1/YJ%20Commercial%20V5%20No%20Audio.m3u8"
              playing
              style={{ aspectRatio: 1.3 }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          marginLeft={{ base: '20px', lg: '-100px' }}
          marginTop={{ base: '-78px', lg: '0' }}
          justifyContent="center"
          ref={ref}
          zIndex="1"
        >
          <Box animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'} opacity={enterCount >= 1 ? 1 : 0}>
            <CustomDivider width={width} />
          </Box>

          <Title
            animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
            opacity={enterCount >= 1 ? 1 : 0}
          >
            Schedule a Call
          </Title>
          <Description
            animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}
            opacity={enterCount >= 1 ? 1 : 0}
          >
            For follow-up questions or to schedule a 1:1 with our{' '}
            <span style={{ color: primaryTheme.colors.brand.violet }}>Director of Business Development</span>, click below.{' '}
          </Description>
          <Box animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'} opacity={enterCount >= 1 ? 1 : 0}>
            <RequestButton
              onClick={() => {
                setIsOpen(true);
              }}
              title="SCHEDULE A CALL"
              isQuestionSection
            />
          </Box>
        </Box>
      </Container>
      <FullScreenModal isOpen={isOpen} onClose={() => setIsOpen(false)} LowerBody={<HaveQuestionModal />} />
    </OuterContainer>
  );
};

export default QuestionSection;
