import { OptionsRouter } from 'react-typesafe-routes';
import { HomePage } from 'pages';

const defaultOptions = {
  footer: true,
  navBar: true,
  isHomePage: false,
  isPlayerPage: false,
  isThankYouPage: false,
  isUpcomingLocationPage: false,
};

const router = OptionsRouter(defaultOptions, (route) => ({
  home: route('/', {
    component: HomePage,
    options: {
      isHomePage: true,
    },
  }),
}));

export default router;
