import { Box, chakra, Text } from '@chakra-ui/react';
import { IconYBlue, ReloadIcon } from 'assets';
import { primaryTheme } from 'theming';

const Container = chakra(Box, {
  baseStyle: {
    background: primaryTheme.colors.brand.darkViolet,
    borderTop: `10px solid ${primaryTheme.colors.brand.lightViolet}`,
    padding: { base: '119px 22px 35px', lg: '57px 50px 53px' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: { md: '126%' },
    letterSpacing: '0.02em',
    color: primaryTheme.colors.brand.white,
    marginBottom: { lg: '48px' },

    span: {
      color: primaryTheme.colors.brand.violet,
    },
  },
});

const SubTitle = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: { md: '100%' },
    letterSpacing: '-0.01em',
    opacity: '0.6',
    marginTop: '41px',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: { base: '140%', md: '160%' },
    marginTop: { base: '25px', lg: '33px' },
    letterSpacing: '-0.01em',
    opacity: 0.6,
  },
});

const DescriptionContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: primaryTheme.colors.brand.white,
    textAlign: 'center',
    padding: { base: '100px 20px', lg: '70px 20px' },
  },
});

const HorizontalDivider = chakra(Box, {
  baseStyle: {
    background: primaryTheme.colors.brand.white,
    height: '2px',
    opacity: '0.25',
    width: '100%',
    margin: '0 auto',
  },
});

const DescriptionText = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: { base: '100%', md: '100%' },
    textTransform: 'uppercase',
    color: primaryTheme.colors.brand.violet,
  },
});

const ITEM19Submitted = ({ setIsSubmitted }: { setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return (
    <Container>
      <Title
        dangerouslySetInnerHTML={{
          __html:
            'Item 19 of our Franchise Agreement <span>demonstrates the revenue and profits of our existing Yoga Joint locations.</span>',
        }}
      />

      <HorizontalDivider />
      <DescriptionContainer>
        <Box>
          <IconYBlue />
        </Box>
        <SubTitle>
          Thank You <br />
          For Your Request
        </SubTitle>
        <Description>Item 19 of our Yoga Joint Franchise Program has been sent to your email address.</Description>
        <Box
          onClick={() => setIsSubmitted(false)}
          cursor="pointer"
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop={{ base: '72px', lg: '101px' }}
          gap="10px"
        >
          <ReloadIcon />
          <DescriptionText>RELOAD FORM</DescriptionText>
        </Box>
      </DescriptionContainer>
      <HorizontalDivider />
    </Container>
  );
};

export default ITEM19Submitted;
