export const submitForm = ({
  firstname,
  lastname,
  email,
  phone,
  desiredlocation,
}: {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  desiredlocation: string;
}) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const url = 'https://api.hsforms.com/submissions/v3/integration/submit/21517867/b8ae9f00-5cdb-4f86-8069-999f73a3b9ab';

    const data = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstname,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastname,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'phone',
          value: phone,
        },
        {
          objectTypeId: '0-1',
          name: 'desired_location',
          value: desiredlocation,
        },
      ],
      context: {
        pageUri: window.location.hostname,
        pageName: 'Franchise',
      },
    };

    const finalData = JSON.stringify(data);

    xhr.open('POST', url);

    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(true);
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        reject(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        reject(xhr.responseText);
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        reject(xhr.responseText);
      }
    };

    xhr.send(finalData);
  });
