import { primaryTheme } from 'theming';
import { Box, chakra, Text, Image, useMediaQuery, Flex } from '@chakra-ui/react';
import {
  BackGroundFive,
  StudioSectionFour,
  StudioSectionOne,
  StudioSectionOneMob,
  StudioSectionThree,
  StudioSectionThreeMob,
  StudioSectionFourMob,
  StudioSectionTwo,
  StudioSectionTwoMob,
} from 'assets';
import { CustomDivider } from 'components/Divider';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { AnimationFrame } from 'utils';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: `url(${BackGroundFive})`,
    backgroundSize: { '3xlB': 'contain' },
    bgRepeat: 'no-repeat !important',
  },
});

const InnerContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: { base: 'column', xlA: 'row' },
    overflow: 'hidden',
    justifyContent: 'center',
    width: '100%',
    paddingTop: { base: '200px', xl: '400px' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: { base: '45px', md: '48px', xlA: '52px' },
    lineHeight: '111%',
    textTransform: 'capitalize',
    color: primaryTheme.colors.brand.white,
    marginTop: '54px',
    gap: { base: '2%', '3xlB': '7%' },
    paddingRight: { base: '50px', xlA: '0' },
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: { base: '18px', md: '20px' },
    lineHeight: '145%',
    color: primaryTheme.colors.brand.white,
    letterSpacing: '0.045em',
    marginTop: '20px',
    maxWidth: { base: '350px', md: '700px', xl: 'auto' },
  },
});
type ImageSectionProps = {
  imageUrl: any[];
  inViewport: boolean;
  enterCount: number;
};

const ImageSection = ({ imageUrl, inViewport, enterCount }: ImageSectionProps) => {
  return (
    <>
      {imageUrl.map((item: any) => (
        <Image
          animation={inViewport && enterCount === 1 ? AnimationFrame.fadeOut : 'none'}
          src={item}
          alt="logo"
          opacity={enterCount >= 1 ? 1 : 0}
          key={item}
          flex="1"
          width={{ base: '100%', lg: '70%' }}
          justifyContent="center"
          alignItems="center"
          maxWidth={{ base: undefined, xl: '317px', '3xlB': '100%' }}
          objectFit={{ xl: 'cover', '2xl': 'unset' }}
          overflow="hidden"
          margin={{ base: 'auto', xl: 0 }}
        />
      ))}
    </>
  );
};

const StudioSection = () => {
  const studioRef = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(studioRef, { threshold: 0.1 });
  const [isMobileView] = useMediaQuery('(max-width: 1024px)');
  const width = isMobileView ? '100px' : '162px';
  const imageRef = useRef<HTMLDivElement>(null);
  const { inViewport: imgViewPort, enterCount: imgEnterCount } = useInViewport(imageRef, { threshold: 0.1 });

  return (
    <OuterContainer>
      <InnerContainer ref={studioRef}>
        <Flex
          flexDir="column"
          justifyContent="center"
          paddingRight={{ base: '0', xlA: '40px', '2xl': '80px' }}
          animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
          opacity={enterCount >= 1 ? 1 : 0}
          marginLeft={{ base: '20px', md: '50px', xl: 'auto' }}
          maxWidth={{ base: '90%', xl: '35% ', '3xlB': '31%' }}
        >
          <Flex
            flexDir="column"
            justifyContent="center"
            animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
            opacity={enterCount >= 1 ? 1 : 0}
            maxW={{ xl: '90%', '3xlB': '100%' }}
          >
            <CustomDivider width={width} />
            <Title>
              A Yoga & Fitness Studio With a{' '}
              <span style={{ color: primaryTheme.colors.brand.violet }}>Unique Competitive Advantage</span>
            </Title>

            <Description>
              Since our inception in 2010, we have expanded to 9 studios in south Florida, and continue to grow.
            </Description>
            <Description>
              Our mission is to provide our members with personalized service and a physical, mental, and emotional workout rooted
              in yoga.{' '}
            </Description>
            <Description>
              <b>And we do this by using a proven strategy based on 4 critical pillars</b>:
            </Description>
          </Flex>
        </Flex>
        {!isMobileView && (
          <Box
            display="flex"
            flex={1}
            maxWidth={{ lg: '50%', xl: '55%', '3xlB': '50%' }}
            height={{ xlA: '667px', '3xlB': '100%' }}
            ref={imageRef}
            justifyContent={{ base: undefined, lgA: 'flex-end' }}
          >
            <ImageSection
              imageUrl={[StudioSectionOne, StudioSectionTwo, StudioSectionThree, StudioSectionFour]}
              inViewport={imgViewPort}
              enterCount={imgEnterCount}
            />
          </Box>
        )}
        {isMobileView && (
          <Box display="flex" flexDirection="column" ref={imageRef} marginTop="30px">
            <ImageSection
              imageUrl={[StudioSectionOneMob, StudioSectionTwoMob, StudioSectionThreeMob, StudioSectionFourMob]}
              inViewport={imgViewPort}
              enterCount={imgEnterCount}
            />
          </Box>
        )}
      </InnerContainer>
    </OuterContainer>
  );
};

export default StudioSection;
