import { primaryTheme } from 'theming';
import { Box, chakra, Flex, Text, Image as ChakraImage } from '@chakra-ui/react';

const Image = chakra(ChakraImage, {
  baseStyle: {
    width: '100%',
  },
});

const Card = chakra(Box, {
  baseStyle: {
    marginBottom: '32px',
    height: 'fit-content',
    display: 'flex',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontWeight: 300,
    fontSize: { base: '17px', md: '20px', lg: '18px' },
    wordBreak: 'break-word',
    color: primaryTheme.colors.brand.white,
    height: { md: 'auto', lg: '200px', xl: 'auto' },
    lineHeight: '150%',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontWeight: 700,
    fontSize: { base: '20px', md: '27px' },
    wordBreak: 'break-word',
    color: primaryTheme.colors.brand.white,
    lineHeight: { base: '130%', md: '120%' },
    texttransform: 'uppercase',
    letterSpacing: '0.05em',
    maxWidth: { base: '150px', md: '206px' },
  },
});

interface IndustryData {
  id: number;
  title: string;
  image: string;
  description: string;
}

interface IndustryCardProps {
  index: number;
  animate?: string;
  item: IndustryData;
}

const IndustryCard = ({ index, item, animate }: IndustryCardProps) => {
  const getFlexDirection = () => {
    return index % 2 === 1 ? 'row-reverse' : 'row';
  };
  const getTextAlignment = () => {
    return index % 2 === 0 ? 'left' : 'right';
  };

  const getMarginLeft = () => {
    return '100px';
  };

  const getMarginRight = () => {
    return '100px';
  };

  const getMarginTop = () => {
    if (index % 3 === 0) {
      return '0px';
    }
    if (index % 3 === 1) {
      return '100px';
    }
    return '200px';
  };

  return (
    <>
      <Card
        width={{ base: '100%', md: 'calc(100% - 70px)' }}
        animation={animate}
        key={item.id}
        marginRight={{ base: 0, md: '16px', xl: getMarginRight() }}
        marginLeft={{ base: 0, md: '16px', xl: getMarginLeft() }}
        marginBottom={{ base: '94px', md: '125px' }}
        marginTop={{ lg: getMarginTop() }}
        background="tranaparent"
        flexDirection={{ base: getFlexDirection(), lg: 'column' }}
      >
        <Box position="relative">
          <Image
            transitionDuration="300ms"
            src={item.image}
            maxWidth={{ base: '200px', md: '255px' }}
            padding={{ base: '0 20px', md: '0' }}
            objectFit="cover"
          />
          <Title marginLeft={{ base: '10px', md: '-20px' }} position="absolute" top="5">
            {item.title}
          </Title>
        </Box>

        <Flex
          flexDirection={{ base: 'row', lg: 'column' }}
          justifyContent="flex-end"
          height={{ base: 'auto' }}
          padding={{ base: '0 20px 0 0', md: '40px 30px 0 30px', lg: '40px 0px' }}
          maxWidth={{ base: '50%', md: '255px' }}
        >
          <Description textAlign={{ base: getTextAlignment(), lg: 'left' }}>{item.description}</Description>
        </Flex>
      </Card>
    </>
  );
};

export default IndustryCard;
