import * as yup from 'yup';

export const franchise = yup.object({
  email: yup.string().default('').email('Enter a valid email').required('Email is required'),
  firstname: yup
    .string()
    .default('')
    .required('First Name is required')
    .min(2, 'First Name is too short')
    .max(50, 'First Name is too long'),
  lastname: yup
    .string()
    .default('')
    .required('Last Name is required')
    .min(2, 'Last Name is too short')
    .max(50, 'Last Name is too long'),
  phone: yup
    .string()
    .default('')
    .required('Phone Number is required')
    .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone Number is invalid'),
  desiredlocation: yup.string().default('').required('Desired location is required'),
});
