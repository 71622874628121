import { primaryTheme } from 'theming';
import { Box, chakra, SimpleGrid, Text } from '@chakra-ui/react';
import { CustomDivider } from 'components/Divider';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { AnimationFrame, Stub } from 'utils';
import IndustryCard from './IndustryCard';
import { BackGroundFive, BackGroundSix } from 'assets';

const OuterContainer = chakra(Box, {
  baseStyle: {
    margin: { base: '0 0 0 20px', md: '0 100px', xlA: '0 auto' },
    paddingBottom: { base: '50px' },
    width: { xl: '78%', '3xlB': '62%' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: { base: '45px', md: '48px', xlA: '52px' },
    lineHeight: '111%',
    textTransform: 'capitalize',
    color: primaryTheme.colors.brand.white,
    marginLeft: { md: '-5px' },
    marginTop: '54px',
    maxWidth: '964px',
    paddingRight: { base: '40px', md: '0' },
  },
});

const InnerContainer = chakra(Box, {
  baseStyle: {
    justifyContent: 'center',
  },
});

const Container = chakra(Box, {
  baseStyle: {
    background: `url(${BackGroundFive}), url(${BackGroundSix}), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
    backgroundPosition: { base: 'top center, bottom' },
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    paddingTop: { base: '122px', md: '300px' },
  },
});

const IndustrySection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref);
  const animationDirection = [AnimationFrame.slideRight, AnimationFrame.slideLeft];
  return (
    <Container>
      <OuterContainer ref={ref}>
        <CustomDivider width="112px" />
        <Title animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}>
          How Yoga Joint Is <span style={{ color: primaryTheme.colors.brand.violet }}>Changing the Yoga & Fitness Industry</span>
        </Title>
        <InnerContainer
          maxWidth={{ md: '1500' }}
          paddingTop={{ base: '60px', md: '90px', xl: '120px' }}
          margin={{ md: 'auto' }}
          ref={ref}
        >
          <SimpleGrid
            width="100%"
            columns={{ base: 1, lg: 3 }}
            ref={ref}
            opacity={enterCount >= 1 ? 1 : 0}
            minChildWidth={{ base: '100%', lg: '33%' }}
            overflow={{ base: 'hidden', md: 'initial' }}
          >
            {Stub.IndustryData.map((item, index) => (
              <IndustryCard
                key={item.id}
                item={item}
                index={index}
                animate={inViewport && enterCount === 1 ? animationDirection[index % 2] : 'none'}
              />
            ))}
          </SimpleGrid>
        </InnerContainer>
      </OuterContainer>
    </Container>
  );
};

export default IndustrySection;
