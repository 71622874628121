import React from 'react';
import { Formik, Form } from 'formik';
import { chakra, Flex, Box, useMediaQuery } from '@chakra-ui/react';
import { PrimaryInput, RequestButton } from 'components';
import { submitForm } from 'utils/hubspot';
import { franchise } from 'utils/yupSchemas';

const FieldContainer = chakra(Flex, {
  baseStyle: {
    padding: { base: '37px 28px', lg: '62px 53px' },
    flexDirection: 'column',
    width: '100%',
  },
});

const FieldRow = chakra(Flex, {
  baseStyle: {
    flexDirection: { base: 'column', lg: 'row' },
    gap: { lg: '78px' },
    width: '100%',
  },
});

const QuestionFields = ({
  setIsSubmitted,
  setFields,
}: {
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setFields: React.Dispatch<React.SetStateAction<{ email: string; firstName: string; lastName: string }>>;
}) => {
  const [isMobileView] = useMediaQuery('(max-width: 768px)');
  return (
    <Box>
      <Formik
        validationSchema={franchise}
        initialValues={franchise.getDefault()}
        onSubmit={async (values) => {
          await submitForm(values);
          setFields({ email: values.email, firstName: values.firstname, lastName: values.lastname });
          setIsSubmitted(true);
        }}
      >
        {() => (
          <Form>
            <FieldContainer>
              <FieldRow>
                <PrimaryInput name="firstname" label="first name" type="text" placeholder="Enter Your First Name" />
                <PrimaryInput name="lastname" label="last name" type="text" placeholder="Enter Your Last Name" />
              </FieldRow>

              <FieldRow>
                <PrimaryInput name="email" label="Email" type="email" placeholder="Enter Your Email Address" />
                <PrimaryInput name="phone" label="Phone" type="number" placeholder="Enter Your Phone Number" isMasked />
              </FieldRow>
              <FieldRow>
                <PrimaryInput
                  style={{ width: isMobileView ? '100%' : '50%' }}
                  name="desiredlocation"
                  label="Desired Location"
                  type="text"
                  placeholder="Enter Your Desired Location"
                />
                <Flex width={{ base: '100%', lg: '50%' }} justifyContent={{ base: 'center', lg: 'flex-end' }} alignSelf="center">
                  <RequestButton
                    width={isMobileView ? '100%' : '296px'}
                    title="submit"
                    type="submit"
                    marginTop={isMobileView ? '20px' : 0}
                  />
                </Flex>
              </FieldRow>
            </FieldContainer>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default QuestionFields;
