import { primaryTheme } from 'theming';
import { Box, chakra, Text, Image, Flex, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { BackGroundThree, FounderImage, PlayButton } from 'assets';
import { CustomDivider } from 'components/Divider';
import { useInViewport } from 'react-in-viewport';
import { useRef } from 'react';
import { AnimationFrame } from 'utils';
import { CustomVideoPlayer } from 'components';

const OuterContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    background: `radial-gradient(50% 50% at 50% 50%, #000000 51.39%, rgba(217, 217, 217, 0) 100%), url(${BackGroundThree})`,
    backgroundSize: 'cover !important',
    bgRepeat: 'no-repeat !important',
    paddingBottom: { base: '100px', lg: '200px' },
    flexDirection: { base: 'column' },
    justifyContent: 'center',
  },
});

const ImageContainer = chakra(Box, {
  baseStyle: {
    marginTop: { base: '29px', md: '85px', lg: '20px' },
  },
});

const TextContainer = chakra(Box, {
  baseStyle: {
    margin: { base: '70px 20px 0 20px', lg: '70px', xl: '93px 0 0 0' },
    display: 'flex',
    flexDirection: 'column',
    alignItems: { md: 'center' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: { base: '45px', md: '35px', xl: '40px' },
    lineHeight: '111%',
    textTransform: 'capitalize',
    color: primaryTheme.colors.brand.white,
    marginTop: { base: '30px', xl: '36px' },
    marginBottom: { xl: '36px' },
    textAlign: { md: 'center' },
  },
});

const VideoContainer = chakra(Flex, {
  baseStyle: {
    overflow: 'hidden',
    justifyContent: 'center',
    padding: { xlA: '0 !important' },
  },
});

const FounderSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.1 });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobileView] = useMediaQuery('(max-width: 1024px)');

  return (
    <OuterContainer>
      <TextContainer
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
        animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}
      >
        <CustomDivider width="112px" />
        <Title>
          A Message <br />
          from Our Founder
        </Title>
      </TextContainer>
      <ImageContainer
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
        animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
      >
        <VideoContainer pos="relative" animation={inViewport && enterCount === 1 ? AnimationFrame.fade : 'none'}>
          <Flex
            style={{
              position: 'absolute',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <PlayButton
              width={isMobileView ? '97px' : '126px'}
              height={isMobileView ? '97px' : '126px'}
              onClick={onOpen}
              cursor="pointer"
              zIndex={2}
              colorOne="#FFD420"
              colorTwo="#354F7C"
              colorThree="#354F7C"
              colorOneHover="#FFD420"
              colorTwoHover="#364C73"
              colorThreeHover="#364C73"
            />
            <Text
              onClick={onOpen}
              style={{
                fontFamily: 'Montserrat',
                fontWeight: 600,
                lineHeight: '128%',
                textAlign: 'center',
                color: '#FFD215',
                textTransform: 'uppercase',
                marginTop: 13,
                cursor: 'pointer',
              }}
              fontSize={{ base: 16, md: 20 }}
            >
              MEET PAIGE HELD
            </Text>
          </Flex>
          <CustomVideoPlayer
            onClose={onClose}
            isOpen={isOpen}
            videoUrl="https://d3kobf7vi81n42.cloudfront.net/WEBSITE/AppleHLS1/Franchise%20Announcement%20Video%2016x9%20v1.m3u8"
          />
          <Image
            alt="Thank You!"
            src={FounderImage}
            animation={AnimationFrame.fade}
            width={{ base: '100%', lg: '55%', '3xlB': '60%' }}
            maxHeight={{ '3xl': '691', '4xl': '100%' }}
          />
        </VideoContainer>
      </ImageContainer>
    </OuterContainer>
  );
};

export default FounderSection;
