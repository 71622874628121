import { Box, chakra, Text } from '@chakra-ui/react';
import { BackGroundOne } from 'assets';
import { useInViewport } from 'react-in-viewport';
import { useRef } from 'react';

const Wrapper = chakra(Box, {
  baseStyle: {
    background: `url(${BackGroundOne})`,
    backgroundPosition: 'bottom center',
    backgroundSize: { '3xlB': 'cover !important' },
    bgRepeat: 'no-repeat !important',
    width: '100% !important',
  },
});

const Container = chakra(Box, {
  baseStyle: {
    width: '100% !important',
    paddingTop: { lg: '200px' },
    // paddingBottom: { base: '100px' },
  },
});

// const OuterContainer = chakra(Box, {
//   baseStyle: {
//     margin: { base: '0 20px', md: '0 60px', xl: '0 auto' },
//     paddingTop: '107px',
//     position: 'relative',
//     width: { xl: '78%', '3xlB': '62%' },
//     paddingBottom: { base: '130px', lg: '0' },
//   },
// });
// const InnerContainer = chakra(Box, {
//   baseStyle: {
//     height: '100%',
//     alignSelf: 'flex-end',
//     maxWidth: { lg: '60%' },
//   },
// });

// const Title = chakra(Text, {
//   baseStyle: {
//     fontFamily: 'Montserrat',
//     fontStyle: 'normal',
//     fontWeight: 500,
//     fontSize: { base: '45px', md: '56px' },
//     lineHeight: { base: '112%', md: '115%' },
//     textTransform: 'capitalize',
//     background: 'linear-gradient(274.64deg, #547AFF 11.11%, #FF42DB 116.92%)',
//     backgroundClip: 'text',
//     maxWidth: { lg: '666px' },
//     marginTop: '44px',
//   },
// });

// const FormContainer = chakra(Box, {
//   baseStyle: {
//     position: { lg: 'absolute' },
//     right: { lg: 0 },
//     top: { lg: '-20%' },
//     display: 'flex',
//     justifyContent: 'center',
//     maxWidth: { lg: '467px' },
//   },
// });

// const Description = chakra(Text, {
//   baseStyle: {
//     fontFamily: 'Montserrat',
//     fontStyle: 'normal',
//     fontWeight: 500,
//     fontSize: { base: '21px', md: '27px' },
//     lineHeight: { base: '132%', md: '132%' },
//     marginTop: { base: '40px', lg: '0' },
//     maxWidth: { lg: '520px' },
//     color: primaryTheme.colors.brand.white,
//   },
// });

const RevenueSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  // const [isMobileView] = useMediaQuery('(max-width: 768px)');
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.2 });
  // const width = isMobileView ? '100px' : '162px';
  // const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <Wrapper>
      <Container
        ref={ref}
        opacity={enterCount >= 1 ? 1 : 0}
        animation={inViewport && enterCount === 1 ? `rotate 2s infinite linear` : 'none'}
      >
        {/* <Box background={{ lg: '#0F0D2D' }} paddingBottom={{ base: '20px', lg: '230px' }}>
          <OuterContainer>
            <InnerContainer>
              {isMobileView && <CustomDivider width={width} />}
              <Description
                animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
                opacity={enterCount >= 1 ? 1 : 0}
              >
                Would you like to know the revenue, profits, and membership counts of our existing Yoga Joint locations?
              </Description>
              <Title
                animation={inViewport && enterCount === 1 ? AnimationFrame.slideLeft : 'none'}
                opacity={enterCount >= 1 ? 1 : 0}
              >
                Download a copy of our studios&apos; financials
              </Title>
              <Box marginTop={{ base: '42px' }}>
                <Image
                  maxWidth={{ lg: '307px' }}
                  height={{ lg: '310px' }}
                  src={isMobileView ? AgreementMob : AgreementModal}
                  opacity={enterCount >= 1 ? 1 : 0}
                  animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
                  position="absolute"
                  zIndex="10"
                />
              </Box>
            </InnerContainer>
            {!isMobileView && (
              <FormContainer
                animation={inViewport && enterCount === 1 ? AnimationFrame.fadeOut : 'none'}
                opacity={enterCount >= 1 ? 1 : 0}
              >
                {!isSubmitted ? (
                  <ITEM19Form setIsSubmitted={setIsSubmitted} />
                ) : (
                  <ITEM19Submitted setIsSubmitted={setIsSubmitted} />
                )}
              </FormContainer>
            )}
          </OuterContainer>
          {isMobileView && (
            <FormContainer
              animation={inViewport && enterCount === 1 ? AnimationFrame.fadeOut : 'none'}
              opacity={enterCount >= 1 ? 1 : 0}
            >
              {!isSubmitted ? (
                <ITEM19Form setIsSubmitted={setIsSubmitted} />
              ) : (
                <ITEM19Submitted setIsSubmitted={setIsSubmitted} />
              )}
            </FormContainer>
          )}
        </Box> */}

        <Box
          width={{ xl: '78%', '3xlB': '62%' }}
          // paddingTop={{ base: '50px', lg: '100px' }}
          margin={{ base: '0 20px', md: '0px 60px', lg: '80px 60px', xl: '100px auto' }}
        >
          <Text color="white" fontSize={14} position="relative" width={{ xl: '80%', '3xl': '85%' }}>
            <span
              style={{
                fontSize: '10px',
                color: 'white',
                position: 'absolute',
              }}
            >
              1
            </span>
            <span
              style={{
                fontSize: '10px',
                color: 'white',
                opacity: 0,
              }}
            >
              1 .
            </span>
            This information is not intended as an offer to sell, or the solicitation of an offer to buy, a franchise. It is for
            informational purposes only. Currently, the following states regulate the offer and sale of franchises: California,
            Hawaii, Illinois, Indiana, Maryland, Michigan, Minnesota, New York, North Dakota, Oregon, Rhode Island, South Dakota,
            Virginia, Washington, and Wisconsin. If you are a resident of one of these states, we will not offer you a franchise
            unless and until we have complied with applicable pre-sale registration and disclosure requirements in your
            jurisdiction.
          </Text>
        </Box>
      </Container>
    </Wrapper>
  );
};

export default RevenueSection;
