import smoothscroll from 'smoothscroll-polyfill';
import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Footer, NavBar } from 'components';

interface PrimaryLayoutProps {
  children: React.ReactElement;
}

const PrimaryLayout = ({ children }: PrimaryLayoutProps) => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <NavBar />
      {children}
      <Footer />
    </Flex>
  );
};

export default PrimaryLayout;
