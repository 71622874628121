import ReactPlayer from 'react-player';
import { CSSProperties } from 'react';
import { hasNativeHLS } from 'utils';

type InLineVideoPlayerProps = {
  style?: CSSProperties;
  videoRef?: any;
  videoUrl: string;
  height?: string;
  width?: string;
  playing?: boolean;
};

const InLineVideoPlayer = ({
  videoRef,
  videoUrl,
  width = '100%',
  height = '100%',
  style,
  playing = true,
}: InLineVideoPlayerProps) => {
  return (
    <ReactPlayer
      playsinline
      className="react-player"
      controls={false}
      ref={videoRef}
      style={style}
      width={width}
      loop
      muted
      height={height}
      url={videoUrl}
      playing={playing}
      config={{
        file: {
          forceHLS: !hasNativeHLS(),
          forceVideo: true,
          hlsVersion: '1.3.0',
          attributes: {
            autoPlay: false,
            playsInline: true,
            controlsList: 'fullscreen',
            disablePictureInPicture: true,
          },
        },
      }}
    />
  );
};

export default InLineVideoPlayer;
