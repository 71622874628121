import { primaryTheme } from 'theming';
import { Box, chakra, Image, useMediaQuery } from '@chakra-ui/react';
import { HeroBgBottomOne, HeroBgBottomThree, HeroBgBottomTwo } from 'assets';
import { AnimationFrame } from 'utils';
import RequestButton from 'components/Buttons/RequestButton';
import { Parallax } from 'react-parallax';
import { FullScreenModal } from 'components';
import { useState } from 'react';
import { HaveQuestionModal } from 'components/Modals';

const Container = chakra(Box, {
  baseStyle: {
    height: { base: '764px', md: '955px' },
    bgRepeat: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: primaryTheme.colors.brand.white,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
  },
});

const InnerContainer = chakra(Box, {
  baseStyle: {
    width: { base: '100%', md: '70%', xl: '40%', '3xlB': '30%' },
    padding: { base: '0px 5px 0px 20px', md: '0' },
  },
});

const Title = chakra(Box, {
  baseStyle: {
    fontWeight: 400,
    fontSize: { base: '50px', md: '67px' },
    lineHeight: '100%',
    letterSpacing: '-0.01em',
    paddingBottom: '20px',
  },
});

const Description = chakra(Box, {
  baseStyle: {
    fontWeight: 300,
    fontSize: { base: '25px', md: '35px' },
    lineHeight: '127.5%',
    letterSpacing: '-0.01em',
    maxWidth: '477px',
  },
});

const BottomImage = chakra(Image, {
  baseStyle: {
    zIndex: 1,
    objectFit: 'cover',
    width: { base: '140px', md: '340px' },
    maxWidth: { base: '130px', md: '240px', xl: '340px', '3xlB': '100%' },
    height: { base: '280px', md: '480px', '3xlB': '100%' },
    flex: '1',
    marginRight: -1,
  },
});

const Hero = () => {
  const [isMobileView] = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Box position="relative">
        <Box style={{ overflow: 'hidden' }}>
          <Box animation={`${AnimationFrame.zoomOut} 5s forwards`}>
            <Parallax
              bgImage={require('../../assets/images/HeroBg.png')}
              bgImageStyle={{
                maxHeight: isMobileView ? '764px' : '955px',
                objectFit: 'cover',
              }}
            >
              <Container>
                <InnerContainer>
                  <Title animation={AnimationFrame.slideRight}>Transform Lives and Build a Community</Title>
                  <Description animation={AnimationFrame.slideLeft}>With the Business Opportunity of a Lifetime</Description>
                  <Box animation={AnimationFrame.slideRight}>
                    <RequestButton
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      title="Schedule A Call"
                    />
                  </Box>
                </InnerContainer>
              </Container>
            </Parallax>
          </Box>
        </Box>

        <Box
          width={{ '3xlB': '60%' }}
          display="flex"
          zIndex="100"
          justifyContent="flex-end"
          marginTop="-150px"
          marginLeft="auto"
          marginRight="0"
          overflow="hidden"
        >
          <BottomImage animation={AnimationFrame.fadeOut} src={HeroBgBottomOne} />
          <BottomImage animation={AnimationFrame.fadeOut} src={HeroBgBottomTwo} />
          <BottomImage animation={AnimationFrame.fadeOut} src={HeroBgBottomThree} />
        </Box>
        <FullScreenModal isOpen={isOpen} onClose={() => setIsOpen(false)} LowerBody={<HaveQuestionModal />} />
      </Box>
    </>
  );
};

export default Hero;
