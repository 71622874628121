import { primaryTheme } from 'theming';
import { chakra, Flex, Text, Box } from '@chakra-ui/react';
import { ReloadIcon, IconYBlue } from 'assets';

const FieldContainer = chakra(Flex, {
  baseStyle: {
    padding: { base: '34px 28px', lg: '0px 53px' },
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: primaryTheme.colors.brand.white,
    textAlign: 'center',
  },
});

const HorizontalDivider = chakra(Box, {
  baseStyle: {
    background: primaryTheme.colors.brand.white,
    height: '2px',
    width: '47px',
    marginTop: '33px',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: { md: '100%' },
    letterSpacing: '-0.01em',
    marginTop: '63px',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: { base: '140%', md: '160%' },
    marginTop: { base: '15px' },
    letterSpacing: '-0.01em',
    maxWidth: { base: '262px', lg: '420px' },
  },
});

const DescriptionText = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: { base: '100%', md: '100%' },
    textTransform: 'uppercase',
    color: primaryTheme.colors.brand.violet,
    padding: '20px 0',
  },
});

const ThankYouMsg = ({ setIsSubmitted }: { setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return (
    <FieldContainer position="relative">
      <Box position="absolute" top="-35px">
        <IconYBlue width="70px" height="70px" />
      </Box>
      <Title>Booking Confirmed</Title>
      <Description>You&apos;re booked with Ernesto Garruto. An invitation has been emailed to you.</Description>
      <HorizontalDivider />

      <Box
        onClick={() => setIsSubmitted(false)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        margin="26px 0 42px"
        gap="10px"
        cursor="pointer"
      >
        <ReloadIcon />
        <DescriptionText>RELOAD FORM</DescriptionText>
      </Box>
    </FieldContainer>
  );
};
export default ThankYouMsg;
