import { Box, chakra, Text, Image, Card, useMediaQuery } from '@chakra-ui/react';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame } from 'utils';

const CardTitle = chakra(Box, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: { base: '130%', md: '35px' },
    textTransform: 'capitalize',
    color: primaryTheme.colors.brand.white,
    margin: { base: '18px auto', xl: '26px 0 31px 0' },
    textAlign: { base: 'center', xl: 'left' },
    maxWidth: { base: '220px', md: '280px' },
  },
});

const CardDescription = chakra(Text, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: { base: '20px', md: '25px' },
    lineHeight: '37.5px',
    textAlign: { base: 'center', xl: 'left' },
    span: {
      color: primaryTheme.colors.brand.violet,
    },
  },
});

interface GetStartedData {
  id: number;
  title: string;
  image: string;
  description: string;
}

interface GetStartedCardProps {
  index: number;
  item: GetStartedData;
}

const GetStartedCard = ({ index, item }: GetStartedCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.1 });
  const [isMobileView] = useMediaQuery('(max-width: 1024px)');
  const border = '1px solid rgba(94, 95, 104, 0.6)';
  const addBorder = index < 2;
  const isFirst = index === 0;
  const isLast = index === 2;

  const animate = [
    `${AnimationFrame.fadeIn} 1s both`,
    `${AnimationFrame.fadeIn} 1s 0.5s both`,
    `${AnimationFrame.fadeIn} 1s 1s both`,
    `${AnimationFrame.fadeIn} 1s 1.5s both`,
  ];

  const getAnimation = (i: number) => {
    return isMobileView ? animate[0] : animate[i];
  };

  return (
    <>
      <Card
        key={item.id}
        borderRadius={{ base: 0 }}
        background="#000"
        borderRight={{ xl: addBorder ? border : 'none' }}
        borderBottom={{ base: index < 2 ? border : 0, xl: 'none' }}
        padding="30px 0"
        display="flex"
      >
        <Box
          ref={ref}
          opacity={enterCount >= 1 ? 1 : 0}
          animation={inViewport && enterCount === 1 ? getAnimation(index) : 'none'}
          display="flex"
          flexDirection="column"
          alignItems={{ base: 'center', xl: 'unset' }}
          marginLeft={!isFirst ? { xl: '95px' } : 0}
          marginRight={!isLast ? { xl: isFirst ? '50px' : '73px' } : 0}
        >
          <Image src={item.image} height="95px" width="95px" marginLeft={{ xl: '-20px' }} />

          <Box>
            <CardTitle>{item.title}</CardTitle>
            <CardDescription dangerouslySetInnerHTML={{ __html: item.description }} />
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default GetStartedCard;
