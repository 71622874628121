import { Box, chakra, Text, useMediaQuery } from '@chakra-ui/react';
import { PrimaryInput, RequestButton } from 'components';
import { Form, Formik } from 'formik';
import { primaryTheme } from 'theming';
import { submitForm } from 'utils/hubspot';
import { franchise } from 'utils/yupSchemas';

const Container = chakra(Box, {
  baseStyle: {
    background: primaryTheme.colors.brand.darkViolet,
    borderTop: `10px solid ${primaryTheme.colors.brand.lightViolet}`,
    padding: { base: '120px 22px 35px', lg: '44px 50px 53px' },
    width: '100%',
  },
});

const Title = chakra(Text, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: { md: '140%' },
    letterSpacing: '0.02em',
    marginBottom: { lg: '32px' },
    color: primaryTheme.colors.brand.violet,
    textTransform: 'uppercase',
  },
});

const FieldContainer = chakra(Box, {
  baseStyle: {
    marginTop: '51px',
  },
});

const HorizontalDivider = chakra(Box, {
  baseStyle: {
    background: primaryTheme.colors.brand.violet,
    height: '1px',
    opacity: '0.5',
    width: '100%',
  },
});

export type DropDown = {
  label: string;
  value: string;
};

const ITEM19Form = ({ setIsSubmitted }: { setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [isMobileView] = useMediaQuery('(max-width: 1024px)');

  return (
    <Container>
      <Title>Enter your information below to receive a detailed summary of our studios financials.</Title>
      {!isMobileView && <HorizontalDivider />}
      <Formik
        validationSchema={franchise}
        initialValues={franchise.getDefault()}
        onSubmit={async (values) => {
          await submitForm(values);
          setIsSubmitted(true);
        }}
      >
        {() => (
          <Form>
            <FieldContainer>
              <PrimaryInput
                style={{ marginBottom: '20px' }}
                name="firstname"
                label="first name"
                type="text"
                placeholder="Enter Your First Name"
              />
              <PrimaryInput
                style={{ marginBottom: '20px' }}
                name="lastname"
                label="last name"
                type="text"
                placeholder="Enter Your Last Name"
              />
              <PrimaryInput
                style={{ marginBottom: '20px' }}
                name="email"
                label="Email"
                type="email"
                placeholder="Enter Your Email Address"
              />
              <PrimaryInput
                style={{ marginBottom: '20px' }}
                name="phone"
                label="Phone"
                type="number"
                placeholder="Enter Your Phone Number"
                isMasked
              />
              <PrimaryInput
                style={{ marginBottom: '20px' }}
                name="desiredlocation"
                label="Desired Location"
                type="text"
                placeholder="Enter Your Desired Location"
              />
              <RequestButton style={{ marginBottom: '20px' }} title="download now" isForm type="submit" />
            </FieldContainer>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ITEM19Form;
