import { useState } from 'react';
import { primaryTheme } from 'theming';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useField, FieldHookConfig, Field } from 'formik';
import { chakra, Flex, Text, Menu, MenuItemOption, MenuOptionGroup, MenuButton, MenuList, Box } from '@chakra-ui/react';

type PrimarySelectInputProps = FieldHookConfig<string> & {
  label: string;
  setLocation?: (location: string) => void;
  location?: string;
  options: Array<{
    label: string;
    value: string;
  }>;
};

const Wrapper = chakra(Flex, {
  baseStyle: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});

const Label = chakra(Text, {
  baseStyle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    color: primaryTheme.colors.white,
  },
});

const ErrorMessage = chakra(Text, {
  baseStyle: {
    marginTop: 1,
    height: '18px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    color: primaryTheme.colors.red[500],
  },
});

const LocationList = chakra(MenuList, {
  baseStyle: {
    minW: '100%',
    padding: '0px',
    bgColor: primaryTheme.colors.black,
    marginRight: { base: '0', md: '100px' },
    border: `2px solid ${primaryTheme.colors.changedOpacityColors.white_pointFour}`,
  },
});

const Option = chakra(MenuItemOption, {
  baseStyle: {
    width: '100%',
    padding: '2.5px',
    fontSize: '16px',
    fontWeight: '400',
    borderRadius: '2px',
    background: primaryTheme.colors.brand.black,
    color: primaryTheme.colors.white,
    _hover: {
      bg: primaryTheme.colors.brand.limeGreen,
      color: primaryTheme.colors.white,
    },
    _focus: {
      bg: primaryTheme.colors.brand.limeGreen,
      color: primaryTheme.colors.white,
    },
  },
});

const PrimarySelectInput = ({ label, setLocation, location, options, placeholder, style, ...props }: PrimarySelectInputProps) => {
  const [field, meta, helpers] = useField(props);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { value } = field;
  const { setValue } = helpers;

  const setLabel = () => {
    const valueLabel = Object.values(options).find((x) => x.value === value);
    return valueLabel?.label || location || placeholder;
  };

  return (
    <>
      <Box visibility="hidden" position="fixed">
        <Field as="select" {...field} {...meta} {...helpers} value={value}>
          {options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Field>
      </Box>
      <Wrapper style={style}>
        <Label>{label}</Label>

        <Menu {...field} closeOnSelect onClose={() => setIsOpen(false)} onOpen={() => setIsOpen(true)}>
          <MenuButton
            as={Box}
            marginTop={2}
            paddingTop={1}
            paddingBottom={1}
            cursor="pointer"
            fontSize="18px"
            borderBottom={`1px solid ${primaryTheme.colors.white}`}
            color={!value && !location ? primaryTheme.colors.brand.placeholder : primaryTheme.colors.white}
          >
            <Box display="flex" justifyContent="space-between">
              {setLabel()}
              <ChevronDownIcon color={primaryTheme.colors.white} transform={isOpen ? 'rotate(180deg)' : 'none'} />
            </Box>
          </MenuButton>
          <LocationList>
            <MenuOptionGroup type="checkbox">
              {options.map((opt) => (
                <Option
                  {...field}
                  value={opt.value}
                  key={opt.value}
                  isChecked={value === opt.value}
                  onClick={() => {
                    setValue(opt.value);
                    if (setLocation) setLocation(opt.label);
                    setIsOpen(false);
                  }}
                >
                  {opt.label}
                </Option>
              ))}
            </MenuOptionGroup>
          </LocationList>
        </Menu>
        <ErrorMessage>{meta.touched && meta.error ? meta.error : null}</ErrorMessage>
      </Wrapper>
    </>
  );
};

export default PrimarySelectInput;
