import { primaryTheme } from 'theming';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Modal, ModalContent, Flex, chakra, IconButton, useOutsideClick } from '@chakra-ui/react';
import { useRef } from 'react';

type FullScreenModalProps = {
  isOpen: boolean;
  onClose: () => void;
  LowerBody?: React.ReactNode;
};

const Container = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto 0',
  },
});

const LowerContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    margin: { base: '0%', md: '0 10%', lg: 'auto' },
    width: { base: '100%', md: '80%', xl: '62%', '3xlB': '40%' },
    position: 'relative',
  },
});

const CrossButton = chakra(IconButton, {
  baseStyle: {
    minWidth: { base: '30px', lg: '48px' },
    height: { base: '30px', lg: '48px' },
    borderWidth: '1px',
    borderRadius: '0px',
    ariaLabel: 'Close',
    color: primaryTheme.colors.white,
    _focus: { outline: '0 !important' },
    borderColor: primaryTheme.colors.white,
  },
});

const Header = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    top: { base: '22px', md: '38px' },
    right: { base: '20px', md: '26px' },
  },
});

const FullScreenModal = ({ isOpen, onClose, LowerBody }: FullScreenModalProps) => {
  const modalRef = useRef(null);
  useOutsideClick({
    ref: modalRef,
    handler: () => onClose(),
  });

  return (
    <Modal closeOnEsc size="full" isOpen={isOpen} onClose={onClose} motionPreset="scale">
      <ModalContent bgColor={`${primaryTheme.colors.changedOpacityColors.black_pointEight}`}>
        <Container>
          <LowerContainer ref={modalRef}>
            <Header>
              <CrossButton
                variant="outlined"
                onClick={() => onClose()}
                icon={<CloseIcon width={{ base: '10px', xl: '48px' }} />}
                aria-label=""
              />
            </Header>
            {LowerBody}
          </LowerContainer>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default FullScreenModal;
