import {
  IndustryDataFive,
  IndustryDataFour,
  IndustryDataOne,
  IndustryDataSix,
  IndustryDataThree,
  IndustryDataTwo,
  Icon401k,
  IconStar,
  IconDiamond,
} from 'assets';

const IndustryData = [
  {
    id: 1,
    title: 'LOYAL FOLLOWING',
    description:
      'Yoga Joint has cultivated a loyal following because we prioritize building a sense of community among our members. ',
    image: IndustryDataOne,
  },
  {
    id: 2,
    title: 'TEACHER TRAINING PROGRAM',
    description:
      'We train all our teachers on Flow and FIIT to ensure that every class delivers a predictable and consistent outcome aligned with our brand standards.',
    image: IndustryDataTwo,
  },
  {
    id: 3,
    title: 'TECHNOLOGY',
    description:
      'From member-facing technology like our custom-built mobile app to our CRM and other internal technology, we are a fully tech-enabled operating business.',
    image: IndustryDataThree,
  },
  {
    id: 4,
    title: 'RECURRING REVENUE BUSINESS MODEL',
    description:
      'A high penetration of recurring revenue with predictable cash flow, low payback periods, and a high-gross profit margin provides a lucrative and stable income stream. ',
    image: IndustryDataFour,
  },
  {
    id: 5,
    title: 'IMPACTFUL OPERATIONS',
    description:
      'We have simplified our operations with easy-to-follow sales and retention processes, efficient studio operations, and a customer service model that ensures every Yoga Joint member has an exceptional experience.',
    image: IndustryDataFive,
  },
  {
    id: 6,
    title: 'SIMPLE PRODUCT MIX',
    description: 'We focus all our efforts on the flawless execution of our two branded classes, FIIT and Flow.',
    image: IndustryDataSix,
  },
];

const GetStartedData = [
  {
    id: 1,
    title: 'Financial Requirements',
    description: 'Must be accredited investor and be able to invest a minimum of <span>$100,000</span>',
    image: Icon401k,
  },
  {
    id: 2,
    title: 'Passionate About Yoga Joint',
    description:
      'We are searching for individuals who are <span style="color: #6184FF">passionate about Yoga Joint</span> and want to be part of building a very successful company by following a proven formula.',
    image: IconStar,
  },
  {
    id: 3,
    title: 'Stringent Qualification Criteria',
    description:
      'This is an exclusive opportunity for candidates with a <span>strong financial background and proven business acumen.</span>',
    image: IconDiamond,
  },
];

const Locations = [
  {
    value: '1',
    label: 'North Ft. Lauderdale',
  },
  {
    value: '2',
    label: 'Downtown Ft. Lauderdale',
  },
  {
    value: '3',
    label: 'Davie',
  },
  {
    value: '4',
    label: 'Deerfield',
  },
  {
    value: '5',
    label: 'Boca Raton',
  },
  {
    value: '6',
    label: 'Pembroke Pines',
  },
  {
    value: '7',
    label: 'Hollywood',
  },
  {
    value: '8',
    label: 'Miami',
  },
  {
    value: '9',
    label: 'Coral Springs',
  },
];

export default { IndustryData, GetStartedData, Locations };
