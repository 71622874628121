import { Box, chakra, Text, useMediaQuery } from '@chakra-ui/react';
import { FooterBg, YogaJointIcon } from 'assets';
import { primaryTheme } from 'theming';

const YjBackground = chakra(Box, {
  baseStyle: {
    backgroundImage: { base: 'none', md: `url(${FooterBg})` },
    paddingBottom: { base: '50px', md: '150px', xlA: '300px' },
    bgSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
});

const HorizontalDivider = chakra(Box, {
  baseStyle: {
    background: primaryTheme.colors.brand.white,
    width: { base: '90%', md: '78%' },
    height: '1px',
    margin: '0 auto',
  },
});

const Container = chakra(Box, {
  baseStyle: {
    width: { base: '90%', md: '78%' },
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const LogoContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    margin: '48px 0',
    alignItems: 'center',
    gap: { base: '26px', md: '50px', lg: '26px' },
    justifyContent: 'space-between',
    width: { base: '100%', md: 'max-content' },
  },
});

const VerticalDivider = chakra(Box, {
  baseStyle: {
    textAlign: 'center',
    opacity: '0.3',
    width: '0px',
    height: { base: '49px', md: '35px' },
    color: primaryTheme.colors.brand.white,
    border: `0.5px solid ${primaryTheme.colors.brand.white}`,
  },
});

const Title = chakra(Box, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '130%',
    textTransform: 'capitalize',
    maxWidth: { base: '110px', md: '200px' },
  },
});

const FooterText = chakra(Text, {
  baseStyle: {
    color: primaryTheme.colors.brand.slateGrey,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: { base: '14px', xl: '16px' },
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    margin: { base: '47px auto  20px auto', md: '60px 0' },
  },
});

const Footer = () => {
  const [isMobileView] = useMediaQuery('(max-width: 768px)');
  return (
    <Box background={primaryTheme.colors.brand.black}>
      {!isMobileView && <HorizontalDivider />}
      <Container>
        <LogoContainer>
          <YogaJointIcon />
          <VerticalDivider />
          <Title>Business Opportunity</Title>
        </LogoContainer>
        {!isMobileView && (
          <FooterText>
            <span
              role="button"
              tabIndex={-619}
              onClick={() => {
                window.open('https://yogajoint.com', '_blank');
              }}
              onKeyDown={() => {
                window.open('https://yogajoint.com', '_blank');
              }}
              style={{ color: '#3CBCEB', cursor: 'pointer' }}
            >
              yogajoint.com
            </span>{' '}
            © {new Date().getFullYear()} Yoga Joint All Rights Reserved{' '}
          </FooterText>
        )}
      </Container>
      {isMobileView && (
        <>
          <HorizontalDivider />
          <Box display="flex" justifyContent="center">
            <FooterText>
              <span
                role="button"
                tabIndex={-619}
                onClick={() => {
                  window.open('https://yogajoint.com', '_blank');
                }}
                onKeyDown={() => {
                  window.open('https://yogajoint.com', '_blank');
                }}
                style={{ color: '#3CBCEB', cursor: 'pointer' }}
              >
                yogajoint.com
              </span>{' '}
              © {new Date().getFullYear()} Yoga Joint All Rights Reserved{' '}
            </FooterText>
          </Box>
        </>
      )}

      <YjBackground />
    </Box>
  );
};

export default Footer;
