import ReactPlayer from 'react-player/lazy';
import { CloseIcon } from '@chakra-ui/icons';
import { primaryTheme } from 'theming';
import { AnimationFrame, hasNativeHLS } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { LogoBlackImg, TransparentPlaceholder } from 'assets';
import { useMediaQuery, Box, chakra, Modal, ModalContent, ModalBody, Image, IconButton } from '@chakra-ui/react';

type CustomVideoPlayerProps = {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string;
};

const Container = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    top: 0,
    zIndex: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundImage: primaryTheme.colors.gradients.customVideoGradient,
  },
});

const TopBar = chakra(Box, {
  baseStyle: {
    display: 'flex',
    marginTop: { base: '20px', md: '56px' },
    alignItems: 'center',
    paddingLeft: { base: '20px', md: '58px' },
    paddingRight: { base: '20px', md: '58px' },
    justifyContent: 'space-between',
  },
});

let timerVal: NodeJS.Timeout;

const CustomVideoPlayer = ({ isOpen, onClose, videoUrl }: CustomVideoPlayerProps) => {
  const [isMobileView] = useMediaQuery('(max-width: 768px)');
  const videoRef = useRef<ReactPlayer>(null);
  const [showTop, setshowTop] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  const headerTimeout = (timer: number) => {
    clearTimeout(timerVal);
    timerVal = setTimeout(() => setshowTop(false), timer);
  };

  const getMouseDir = (yAxis: number) => {
    const upperHalf = window.innerHeight / 2;
    if (yAxis < upperHalf && !showTop) {
      setshowTop(true);
      headerTimeout(3000);
    } else if (yAxis > upperHalf && showTop) {
      setshowTop(false);
      headerTimeout(1000);
    }
  };

  useEffect(() => {
    if (isPaused) {
      setshowTop(true);
    }
  }, [isPaused]);

  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose} id="custom-video-player">
      <ModalContent
        padding="0px"
        bgColor={primaryTheme.colors.black}
        onMouseMove={(e) => {
          if (!isPaused) {
            getMouseDir(e.clientY);
          }
        }}
      >
        <Container
          animation={showTop ? AnimationFrame.videoFadeInAnimation : AnimationFrame.videoFadeOutAnimation}
          opacity={showTop ? 1 : 0}
        >
          <TopBar>
            <Image borderRadius="32px" src={LogoBlackImg} fallbackSrc={TransparentPlaceholder} width="165px" />
            <IconButton
              borderRadius="0px"
              color={primaryTheme.colors.white}
              borderColor={primaryTheme.colors.white}
              borderWidth="1px"
              _hover={{
                color: primaryTheme.colors.black,
                borderColor: primaryTheme.colors.black,
                background: primaryTheme.colors.white,
              }}
              _focus={{ outline: '0 !important' }}
              variant="outlined"
              aria-label="Close"
              onClick={() => onClose()}
              icon={<CloseIcon />}
            />
          </TopBar>
        </Container>
        <ModalBody padding="0px" position={isMobileView ? undefined : 'relative'}>
          <ReactPlayer
            ref={videoRef}
            controls
            width="100%"
            height={isMobileView ? undefined : '100vh'}
            onPause={() => {
              setshowTop(true);
              setIsPaused(true);
            }}
            style={
              isMobileView
                ? {
                    top: '25%',
                    position: 'absolute',
                  }
                : undefined
            }
            onPlay={() => {
              setIsPaused(false);
            }}
            playing
            url={videoUrl}
            config={{
              file: {
                forceHLS: !hasNativeHLS(),
                forceVideo: true,
                hlsVersion: '1.3.0',
                attributes: {
                  autoPlay: true,
                  playsInline: true,
                  controlsList: 'fullscreen',
                  disablePictureInPicture: true,
                },
              },
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomVideoPlayer;
