import { useState } from 'react';
import { primaryTheme } from 'theming';
import { chakra, Flex, Text, Box, useMediaQuery } from '@chakra-ui/react';
import { QuestionFields, ThankYouMsg } from 'components';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

const OuterContainer = chakra(Box, {
  baseStyle: {
    background: primaryTheme.colors.brand.darkViolet,
  },
});

const Container = chakra(Flex, {
  baseStyle: {
    color: primaryTheme.colors.white,
    background: 'linear-gradient(274.64deg, rgba(84, 122, 255, 0.2) 11.11%, rgba(255, 66, 219, 0.5) 116.92%)',
    margin: 'auto',
    borderTop: `10px solid ${primaryTheme.colors.brand.lightViolet}`,
    padding: { base: '26px', lg: '58px' },
    gap: '34px',
    flexDirection: { base: 'column', lg: 'row' },
  },
});

const Title = chakra(Text, {
  baseStyle: {
    lineHeight: { base: '100%', lg: '115%' },
    fontSize: { base: '40px', lg: '50px' },
    fontWeight: '400',
    letterSpacing: '-0.01em',
    textTransform: 'capitalize',
  },
});

const Description = chakra(Text, {
  baseStyle: {
    fontSize: { base: '19px', lg: '24px' },
    fontWeight: '400',
    alignItems: 'center',
    lineHeight: '130%',
    letterSpacing: '-0.01em',
    width: { base: '95%', md: '68%' },
    marginTop: '27px',
  },
});

const HaveQuestionModal = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMobileView] = useMediaQuery('(max-width: 768px)');
  const [isSmallScreen] = useMediaQuery('(max-width: 1441px)');
  const [eventScheduled, setEventScheduled] = useState(false);
  const [fields, setFields] = useState({ email: '', firstName: '', lastName: '' });

  const getHeight = () => {
    if (isMobileView) return '530px';
    return isSmallScreen ? '650px' : '700px';
  };
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setEventScheduled(true);
    },
  });

  return (
    <OuterContainer>
      <Container>
        {!isSubmitted && !eventScheduled && (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Title>Schedule a Call</Title>
            <Description>
              For follow-up questions or to schedule a 1:1 with our{' '}
              <span style={{ color: '#C081FC' }}>Director of Business Development</span>, please fill out the form below.
            </Description>
          </Box>
        )}
        {isSubmitted && !eventScheduled && (
          <Description marginTop="0" width={{ base: '250px', md: '450px', lg: 'auto' }}>
            Please, select day and time for your Zoom call with our{' '}
            <span style={{ color: '#C081FC' }}>Director of Business Development</span>.
          </Description>
        )}
      </Container>

      {!isSubmitted && <QuestionFields setFields={setFields} setIsSubmitted={setIsSubmitted} />}
      {isSubmitted && !eventScheduled && (
        <Box>
          <InlineWidget
            url={`https://meetings.hubspot.com/ernesto-garruto?autofill=true&firstName=${fields.firstName}&lastName=${fields.lastName}`}
            styles={{
              height: getHeight(),
              width: '100%',
            }}
            prefill={{
              email: fields.email,
              name: `${fields.firstName} ${fields.lastName}`,
            }}
          />
        </Box>
      )}
      {eventScheduled && <ThankYouMsg setIsSubmitted={setIsSubmitted} />}
    </OuterContainer>
  );
};
export default HaveQuestionModal;
