import InputMask from 'react-input-mask';
import { primaryTheme } from 'theming';
import { chakra, Flex, Input, Text } from '@chakra-ui/react';
import { useField, FieldHookConfig } from 'formik';

type PrimaryInputProps = FieldHookConfig<string> & {
  label: string;
  mask?: string;
  isMasked?: boolean;
};

const Wrapper = chakra(Flex, {
  baseStyle: {
    width: '100%',
    height: 'fit-content',
    flexDirection: 'column',
    marginBottom: '20px',
  },
});

const Label = chakra(Text, {
  baseStyle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '140%',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    color: primaryTheme.colors.brand.white,
  },
});

const InputField = chakra(Input, {
  baseStyle: {
    opacity: 0.8,
    borderRadius: 0,
    fontSize: '18px',
    paddingBottom: '8px',
    background: 'transparent',
    _focusVisible: { outline: 'none' },
    color: primaryTheme.colors.brand.white,
    borderBottom: `1px solid ${primaryTheme.colors.brand.white}`,
  },
});

const ErrorMessage = chakra(Text, {
  baseStyle: {
    marginTop: 1,
    height: '18px',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    color: primaryTheme.colors.red[500],
  },
});

const PrimaryInput = ({ label, placeholder, mask = '999-999-9999', isMasked, style, ...props }: PrimaryInputProps) => {
  const [field, meta] = useField(props.name);
  const { type = '' } = props;

  return (
    <Wrapper style={style}>
      <Label>{label}</Label>
      {!isMasked ? (
        <InputField {...field} type={type} variant="unstyled" placeholder={placeholder} />
      ) : (
        <InputField {...field} mask={mask} as={InputMask} variant="unstyled" placeholder={placeholder} />
      )}
      <ErrorMessage>{meta.touched && meta.error ? meta.error : null}</ErrorMessage>
    </Wrapper>
  );
};

export default PrimaryInput;
