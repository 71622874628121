import { Box, chakra, SimpleGrid, useMediaQuery } from '@chakra-ui/react';
import { BackGroundFour } from 'assets';
import { CustomDivider } from 'components';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { primaryTheme } from 'theming';
import { AnimationFrame, Stub } from 'utils';
import GetStartedCard from './GetStartedCard';

const OuterContainer = chakra(Box, {
  baseStyle: {
    margin: { base: '0 20px', lg: '0 auto', '3xlA': '0 auto' },
    paddingBottom: { base: '100px', lg: '300px' },
    position: 'relative',
    width: { lg: '78%', '3xlB': '62%' },
  },
});

const InnerContainer = chakra(Box, {
  baseStyle: {
    background: { base: `url(${BackGroundFour})`, lg: 'none' },
    backgroundPosition: 'center !important',
    bgRepeat: 'no-repeat !important',
    paddingBottom: { base: '40px', md: '155px' },
    margin: { lg: '0 auto', '3xlA': '0 auto' },
    width: { lg: '78%', '3xlB': '62%' },
  },
});

const Title = chakra(Box, {
  baseStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: { base: '45px', md: '52px' },
    lineHeight: '111%',
    textTransform: 'capitalize',
    color: primaryTheme.colors.brand.white,
    marginTop: '54px',
    maxWidth: '964px',
    textAlign: { base: 'center', xl: 'left' },
  },
});

const GetStartedSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(ref, { threshold: 0.1 });
  const [isMobileView] = useMediaQuery('(max-width: 768px)');
  const width = isMobileView ? '100px' : '112px';

  return (
    <>
      <InnerContainer>
        <Box display={{ base: 'flex', xl: 'block' }} justifyContent={{ base: 'center', xl: 'flex-start' }}>
          <CustomDivider width={width} />
        </Box>
        <Title
          opacity={enterCount >= 1 ? 1 : 0}
          ref={ref}
          animation={inViewport && enterCount === 1 ? AnimationFrame.slideRight : 'none'}
        >
          What You Need <br />
          <span style={{ color: primaryTheme.colors.brand.violet }}>To Get Started</span>
        </Title>
      </InnerContainer>
      <OuterContainer>
        <SimpleGrid
          width="100%"
          columns={{ lg: 3 }}
          ref={ref}
          opacity={enterCount >= 1 ? 1 : 0}
          minChildWidth={{ base: '100%', xl: '33%' }}
          overflow={{ base: 'hidden', md: 'initial' }}
        >
          {Stub.GetStartedData.map((item, index) => (
            <GetStartedCard key={item.id} item={item} index={index} />
          ))}
        </SimpleGrid>
      </OuterContainer>
    </>
  );
};

export default GetStartedSection;
