import { Box, Text } from '@chakra-ui/react';

const Disclaimer = () => {
  return (
    <Box width={{ xl: '78%', '3xlB': '62%' }} margin={{ base: '0 20px', md: '0px 60px', lg: '80px 60px', xl: '20px auto 180px' }}>
      <Text color="white" fontSize={14} position="relative" width={{ xl: '80%', '3xl': '85%' }}>
        <span
          style={{
            fontSize: '10px',
            color: 'white',
            position: 'absolute',
          }}
        >
          1
        </span>
        <span
          style={{
            fontSize: '10px',
            color: 'white',
            opacity: 0,
          }}
        >
          1 .
        </span>
        This information is not intended as an offer to sell, or the solicitation of an offer to buy, a franchise. It is for
        informational purposes only. Currently, the following states regulate the offer and sale of franchises: California,
        Hawaii, Illinois, Indiana, Maryland, Michigan, Minnesota, New York, North Dakota, Oregon, Rhode Island, South Dakota,
        Virginia, Washington, and Wisconsin. If you are a resident of one of these states, we will not offer you a franchise
        unless and until we have complied with applicable pre-sale registration and disclosure requirements in your jurisdiction.
      </Text>
    </Box>
  );
};

export default Disclaimer;
