import { primaryTheme } from 'theming';
import { useEffect, useState, useRef } from 'react';
import { Box, Button, chakra, useMediaQuery } from '@chakra-ui/react';
import { IconY, YogaJointIcon } from 'assets';
import { AnimationFrame } from 'utils';
import { FullScreenModal, HaveQuestionModal } from 'components';

const Container = chakra(Box, {
  baseStyle: {
    zIndex: 20,
    width: '100%',
    display: 'flex',
    backgroundColor: 'transparent',
    justifyContent: { base: undefined, md: 'space-between' },
    position: 'fixed',
    paddingBottom: '25px',
    paddingTop: { base: '0px', lg: '25px' },
    paddingLeft: { base: '14px', md: undefined },
  },
});

const LogoContainer = chakra(Box, {
  baseStyle: {
    display: 'flex',
    margin: { base: '20px 0 0 0', lg: '0 0 0 61px' },
    alignItems: 'center',
    gap: '26px',
  },
});

const VerticalDivider = chakra(Box, {
  baseStyle: {
    textAlign: 'center',
    opacity: '0.67',
    width: '0px',
    height: '35px',
    color: primaryTheme.colors.brand.white,
    border: `1px solid ${primaryTheme.colors.brand.white}`,
  },
});

const Title = chakra(Box, {
  baseStyle: {
    color: primaryTheme.colors.brand.white,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '130%',
    textTransform: 'capitalize',
    maxWidth: { base: '100px', lg: '112px' },
  },
});

const RequestButton = chakra(Button, {
  baseStyle: {
    width: { base: '216px', lg: '243px' },
    height: { base: '44px', lg: '50px' },
    margin: { base: '10px 10px 10px 0', md: '20px 50px 0 0', lg: '0px 50px 0 0' },
    background: primaryTheme.colors.brand.white,
    borderRadius: { base: '149', lg: '168px' },
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: { base: '12', lg: '14px' },
    lineHeight: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: primaryTheme.colors.brand.cyprus,
  },
});

const NavBar = () => {
  const [scrolled, setScrolled] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isMobileView] = useMediaQuery('(max-width: 767px)');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (scrollRef.current) {
        if (window.pageYOffset > scrollRef.current.offsetTop) setScrolled(true);
        else setScrolled(false);
      }
    });
  }, []);
  const isHeaderAbsolute = () => true;

  const getBackgroundColor = () => {
    if (scrolled) return primaryTheme.colors.changedOpacityColors.black_pointSevenSeven;
    if (isHeaderAbsolute()) return 'transparent';

    return primaryTheme.colors.brand.black;
  };
  return (
    <>
      <Container ref={scrollRef} backgroundColor={getBackgroundColor()}>
        <LogoContainer gap={scrolled && isMobileView ? '6px' : '26px'}>
          {scrolled && isMobileView ? <IconY /> : <YogaJointIcon />}
          <VerticalDivider />
          <Title>Business Opportunity</Title>
          {scrolled && isMobileView && (
            <RequestButton
              onClick={() => {
                setIsOpen(true);
              }}
              animation={AnimationFrame.slideRight}
            >
              Schedule A Call
            </RequestButton>
          )}
        </LogoContainer>
        {!isMobileView && (
          <RequestButton
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Schedule A Call
          </RequestButton>
        )}
      </Container>
      {!isHeaderAbsolute() && <Box background={primaryTheme.colors.black} width="100%" height="100px" />}
      <FullScreenModal isOpen={isOpen} onClose={() => setIsOpen(false)} LowerBody={<HaveQuestionModal />} />
    </>
  );
};

export default NavBar;
