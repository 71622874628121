import { chakra } from '@chakra-ui/react';
import { Hero, IndustrySection, QuestionSection, StudioSection, GetStartedSection, FounderSection, Disclaimer } from 'components';
import { primaryTheme } from 'theming';

const Container = chakra('div', {
  baseStyle: {
    backgroundColor: primaryTheme.colors.brand.black,
  },
});
const HomePage = () => {
  return (
    <Container>
      <Hero />
      <StudioSection />
      <IndustrySection />
      <FounderSection />
      <GetStartedSection />
      <QuestionSection />
      <Disclaimer />
    </Container>
  );
};

export default HomePage;
