import { Box, BoxProps, useDisclosure } from '@chakra-ui/react';

const PlayButton = ({
  colorOne,
  colorTwo,
  colorThree,
  colorOneHover,
  colorTwoHover,
  colorThreeHover,
  ...props
}: BoxProps & {
  colorOne?: string;
  colorOneHover?: string;
  colorTwo?: string;
  colorTwoHover?: string;
  colorThree?: string;
  colorThreeHover?: string;
}) => {
  const { isOpen: isHovered, onOpen: onPointerEnter, onClose: onPointerLeave } = useDisclosure();

  return (
    <Box
      as="svg"
      width="152px"
      height="152px"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      onPointerEnter={() => onPointerEnter()}
      onPointerLeave={() => onPointerLeave()}
    >
      <g opacity="0.86">
        <path
          d="M104 76.5C104 91.6878 91.6878 104 76.5 104C61.3122 104 49 91.6878 49 76.5C49 61.3122 61.3122 49 76.5 49C91.6878 49 104 61.3122 104 76.5Z"
          fill={isHovered ? colorOneHover || '#FFD215' : colorOne || '#FFD215'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76 128C104.719 128 128 104.719 128 76C128 47.2812 104.719 24 76 24C47.2812 24 24 47.2812 24 76C24 104.719 47.2812 128 76 128ZM70.5 85.5263L87 76L70.5 66.4737V85.5263Z"
          fill={isHovered ? colorTwoHover || '#030A15' : colorTwo || '#030A15'}
        />
      </g>
      <circle
        cx="76"
        cy="76"
        r="75.5"
        stroke={isHovered ? colorThreeHover || 'black' : colorThree || 'black'}
      />
    </Box>
  );
};
export default PlayButton;
