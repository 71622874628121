import { Box } from '@chakra-ui/react';

interface CustomDividerProps {
  width: string;
}
const CustomDivider = ({ width }: CustomDividerProps) => {
  return <Box width={width} height="7px" background="linear-gradient(274.64deg, #547AFF 11.11%, #FF42DB 116.92%)" />;
};

export default CustomDivider;
